html {
  scroll-behavior: smooth;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0px solid #f0f0f0 !important;
  content: "";
}

.ant-card-head {
  border-radius: 1em 1em 0 0 !important;
  background: #1890ff !important;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: black !important;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
}

.ant-legacy-form-item {
  margin-bottom: 0px !important;
}

.ant-input-affix-wrapper::before {
  display: none;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #2aafe8 0%,
    #1982af 51%,
    #1982af 100%
  ) !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;
  // box-shadow: 0 0 20px #eee !important;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #00aee9;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #00aee9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  color: white !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.btn-grad:hover {
  background-position: right center !important;
  /* change the direction of the change here */
  color: #fff !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.107) !important;
  text-decoration: none !important;
}

.card-grad {
  box-shadow: 0 0 20px rgba(62, 62, 62, 0.107) !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;
  // box-shadow: 0 0 20px #eee !important;
  border-radius: 10px !important;
}

.card-grad:hover {
  background-image: linear-gradient(
    to right,
    #27aee7 0%,
    #1982af 51%,
    #1982af 100%
  ) !important;
  background-position: right center !important;
  background-size: 200% auto !important;
  color: white !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.107) !important;
  border-radius: 10px !important;
}

.card-grad:hover .color-change {
  transition: 0.5s !important;
  color: white !important;
}

.ant-card-extra {
  float: none !important;
  /* margin-left: auto; */
  margin: auto !important;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-spin-container::after {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100vw !important;
  height: 100vh !important;
  background: #ffffff;
  opacity: 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
}

.slick-initialized .slick-slide {
  display: block;
  margin-right: 4em !important;
  padding: 1em !important;
  min-width: 14em !important;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 1em !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-responsive-modal-closeButton {
  background-color: rgba(255, 255, 255, 0.704) !important;
  border-radius: 4em !important;
  // top: 104px !important;
  right: 15px !important;
  z-index: 1 !important;
}

.ant-layout-sider-children {
  box-shadow: 0px 0px 20px 8px rgba(155, 155, 155, 0.1);
  height: 100vh !important;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

.react-responsive-modal-container {
  height: auto !important;
  outline: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  text-align: center;
}

.react-responsive-modal-modal {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  // max-width: 100% !important;
  background: #ffffff00 !important;
  box-shadow: none !important;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white !important;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff !important;
}

.at-toolbar {
  display: none !important;
}

.ant-tooltip-inner {
  color: white !important;
}

.inputs-group {
  svg {
    width: 25px;
    height: 25px;
  }

  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 8px rgba(0, 180, 188, 0.17);
  padding: 20px 50px;

  .ant-form-item {
    margin-bottom: 0;
    border-bottom: 1px solid #bdb2b2;

    &:last-child {
      margin-bottom: 2rem;
      border-bottom: none;
    }

    .ant-form-explain {
      padding-left: 1rem;
    }

    .ant-form-item-label {
      color: #00b4bc;
    }
  }

  .ant-legacy-form-vertical .ant-legacy-form-item {
    padding-bottom: 80px !important;
  }

  span.ant-input-affix-wrapper {
    box-shadow: none;
  }

  .ant-legacy-form-vertical .ant-legacy-form-item textarea.ant-input,
  input.ant-input {
    box-shadow: none;
    border: none;
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

a.ql-action:after {
  border-right: 1px solid #ccc;
  content: "Save" !important;
  margin-left: 16px;
  padding-right: 8px;
}

a.ql-remove:before {
  content: "Remove" !important;
  margin-left: 8px;
}

.ql-tooltip:before {
  content: "Link:" !important;
}

.ql-snow .ql-tooltip {
  margin: auto !important;
  left: 0 !important;
  width: 28em !important;
  right: 0 !important;
  position: fixed !important;
  margin-top: 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}

.ant-card-cover img {
  // background: lightgrey !important;
  border-radius: 2px 2px 0 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  /* border: 1px solid #e9e9e9; */
  // color: white !important;
  height: 60px !important;
  padding: 10px 11px !important;
  border: 1px solid white !important;
  border-radius: 0px !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: text !important;
  font-weight: 500 !important;
}

body {
  overflow-x: hidden !important;
}

.editor-wrapper .quill .ql-container {
  border: 0px solid #ebedf0 !important;
  min-height: 380px;
  max-height: 1000em !important;
  overflow: auto;
}

.react-player__shadow {
  background: rgba(255, 255, 255, 0.271) !important;
  border-radius: 64px;
  width: 64px;
  height: 64px;
  opacity: 0.7 !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.react-player__preview {
  height: 40em !important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: white !important;
}

.ant-list-item-meta-description {
  color: white;
  font-size: 14px;
  line-height: 15px !important;
}

.ant-spin-dot-spin {
  position: fixed !important;
}

.react-responsive-modal-modal {
  // max-width: 75vh !important;
  margin: 1.2rem !important;
  padding: 0rem !important;
}

@media (max-width: 600px) {
  .react-responsive-modal-container {
    height: 65em !important;
    outline: 0;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    text-align: center;
  }

  .react-responsive-modal-modal {
    display: block !important;
    margin: 0rem !important;
    max-width: 100vw !important;
  }

  .btn.get-in-touch {
    background: #201a20;
    border-radius: 0 0 0.6em 0.6em;
    color: white;
    display: inline-block;
    font-size: 14px;
    height: 44px;
    font-weight: 500;
    line-height: 50px;
    position: fixed;
    right: -9px;
    top: 16em !important;
    text-align: center;
    text-decoration: none;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    z-index: 99;
    transform-origin: top right;
    width: 119px;
    /* Hover styles, 
  media queries */
  }
}

.btn.get-in-touch {
  background: rgb(0, 174, 233);
  border-radius: 0 0 0.6em 0.6em;
  color: white;
  display: inline-block;
  font-size: 14px;
  /* height: 38px; */
  font-weight: 500;
  line-height: 50px;
  position: fixed;
  right: 0;
  top: 20em;
  text-align: center;
  text-decoration: none;
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  z-index: 99;
  transform-origin: top right;
  width: 119px;
  /* Hover styles, 
  media queries */
}

.btn.fav {
  background: rgb(0, 174, 233);
  border-radius: 0 0 0.6em 0.6em;
  color: white;
  display: inline-block;
  font-size: 14px;
  /* height: 38px; */
  line-height: 50px;
  font-weight: 500;
  position: fixed;
  right: 0;
  top: 29em;
  text-align: center;
  text-decoration: none;
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  z-index: 99;
  transform-origin: top right;
  width: 120px;
  /* Hover styles, 
  media queries */
}
